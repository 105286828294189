<template>
    <h1 class="mt-14 mb-1.5">Payment Details</h1>
    <p class="mb-4 text-slate-500">Verify the information below and click Next Step to pay your deposit.</p>
    <form class="grid py-3 gap-5" method="post" :action="`/early-deposit/${emdId}/verify-client`" @input="toggleSubmitBtn">
        <div>
            <div class="text-slate-800 mb-1 required-field">Property Address:</div>
            <Skeleton v-if="loading" key="address" />
            <input
                v-else
                type="text"
                class="form-input w-full rounded read-only:bg-[#f2f2f2]"
                :value="address"
                readonly
            />
        </div>

        <div>
            <div class="text-slate-800 mb-1 required-field">Email:</div>
            <Skeleton v-if="loading" key="email" />
            <input
                v-else
                v-model="loginForm.email"
                type="email"
                name="email"
                class="form-input w-full rounded"
                placeholder="example@email.com"
                pattern="^[^\s@]+@[^\s@]+\.[^\s@]+$"
                list="client-email-list"
                required
                autocomplete="email"
            />
            <datalist id="client-email-list">
                <option v-for="client in clients">{{ client.email }}</option>
            </datalist>
            <span class="input-error-msg text-rose-600">
                Please enter a valid email address
            </span>
        </div>

        <div class="phone-section">
            <div class="text-slate-800 mb-1 required-field">Phone:</div>
            <Skeleton v-if="loading" key="phone" />
            <IntlTelInput
                v-else
                ref="intlTelInputRef"
                :options="{
                    containerClass: 'block',
                    initialCountry: 'us',
                }"
                :inputProps="{
                    name: 'phone',
                    class: { 'form-input w-full rounded': true, invalid: !isPhoneValid },
                    required: true,
                    list: 'client-phone-list',
                    autocomplete: 'tel',
                }"
                :value="loginForm.phone"
                @changeNumber="loginForm.phone = $event"
                @changeValidity="isPhoneValid = $event"
            />
            <datalist id="client-phone-list">
                <option v-for="client in clients">{{ client.phone }}</option>
            </datalist>
            <span class="text-rose-600 phone-error-msg">
                Please enter a valid phone number
            </span>
        </div>

        <div class="mt-2">
            <Skeleton v-if="loading" key="tos" />
            <label v-else>
                <input
                    v-model="loginForm.tos"
                    type="checkbox"
                    name="tos"
                    class="form-checkbox mr-2 rounded"
                    required
                />
                <span class="text-slate-500 text-sm required-field">
                    I agree to <a :href="termsLink">Terms of Service</a> and <a :href="privacyLink">Privacy Policy</a>
                </span>
            </label>
        </div>

        <!-- @click="goToPayment" -->
        <button
            class="block w-full text-center bg-brand-primary my-10 px-4 py-2 font-medium text-white rounded transition-opacity disabled:opacity-30"
            :disabled="isSubmitDisabled"
        >
            Next Step →
        </button>
    </form>
</template>

<script lang="ts" setup>
import Skeleton from 'primevue/skeleton';
import { computed, defineAsyncComponent, reactive, ref, watch } from 'vue';

const loading = computed(() => loadingIntlTelCss.value);
const loadingIntlTelCss = ref(true)
const emdId = ref(window.location.href.split('/').at(-1));

/** legacy from @see LoginFormComponent.vue  */
document.querySelector('[page-loading]')?.remove();

const props = defineProps<{
    termsLink: string,
    privacyLink: string,
    address: string,
    clients: Array<{ email: string, phone: string }>,
}>();

// must be loaded async to avoid style conflict with jquery plugin version in company portal
import("~tel-modern/build/css/intlTelInput.css").then(() => loadingIntlTelCss.value = false);

const IntlTelInput = defineAsyncComponent({
    // @ts-expect-error : Issue from aliased duplication of intl-tel-input dependency -- REMOVE THIS @ts COMMENT WHEN OTHER INTL-TEL-INPUT IS GONE
    loader: () => import("intl-tel-input-modern/vueWithUtils"),
    loadingComponent: Skeleton,
});

const intlTelInputRef = ref<typeof IntlTelInput>();
const isPhoneValid = ref(false);
const loginForm = reactive({
    email: '',
    phone: '',
    tos: '',
})

if (props.clients.length === 1) {
    loginForm.email = props.clients[0].email;
    loginForm.phone = props.clients[0].phone;
}

// Autofill phone from matching email
watch(() => loginForm.email, (newEmail) => {
    if (!newEmail) return;

    const foundClient = props.clients.find(({ email }) => {
        return email === newEmail;
    });

    if (foundClient?.phone) {
        intlTelInputRef.value?.instance?.setNumber(foundClient.phone);
    }
 });

// Autofill email from matching phone
watch(() => loginForm.phone, (newPhone) => {
    if (!newPhone) return;

    const foundClient = props.clients.find(({ phone }) => {
        return phone === newPhone;
    });

    if (foundClient?.email) {
        loginForm.email = foundClient.email;
    }
 });

const isSubmitDisabled = ref(true);
const toggleSubmitBtn = ({ currentTarget }: Event) => {
    if (currentTarget instanceof HTMLFormElement) {
        isSubmitDisabled.value = !(currentTarget.checkValidity() && isPhoneValid.value);
    }
}
</script>

<style scoped lang="scss">
:deep(.iti) {
  --iti-path-flags-1x: url('~tel-modern/build/img/flags.webp');
  --iti-path-flags-2x: url('~tel-modern/build/img/flags@2x.webp');
  --iti-path-globe-1x: url('~tel-modern/build/img/globe.webp');
  --iti-path-globe-2x: url('~tel-modern/build/img/globe@2x.webp');
}

.required-field::after {
    content: '*';
    color: red;
}

.input-error-msg,
.phone-section:has(.invalid:focus, .invalid:placeholder-shown) .phone-error-msg,
.phone-section:not(:has(.invalid)) .phone-error-msg {
    display: none;
}

input[type=text], input[type=tel], input[type=email] {
    &:not(:placeholder-shown, :focus) {
        &:invalid, &.invalid {
            @apply border-2;
            @apply border-rose-600;

            ~ .input-error-msg {
                display: initial;
            }
        }
    }
}
</style>
