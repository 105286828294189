<template>
<Modal
    v-model:visible="showUpdateModal"
    body-class="update-emd-modal"
    footer-class="justify-end"
    title="Update EMD"
    fullscreen-on-mobile
    :event-to-close-function="clearUpdateRef"
>
    <p v-if="error" class="bg-rose-100 text-danger py-4 px-6 rounded-md text-base">
        There was an error updating this record. Please try again, or contact support if you continue to experience the problem.
    </p>

    <form class="grid grid-cols-[1fr_3fr] gap-y-4" @submit.prevent="submitUpdate">
        <span class="pt-2">Property Address</span>
        <!-- <AddressAutocompleteSearch html-input-class="form-control p-4 font-[Hind,'Open_Sans']" :initial-value="earlyDepositPayload.address" @location-selected="onLocationSelected" /> -->
        <input class="form-control p-3" name="address" v-model="payloadFields.address" required>

        <span class="pt-2">Deposit Amount</span>
        <AutoNumericInput
            name="deposit_amount"
            class="form-control p-3"
            v-model.cents="payloadFields.deposit_amount!"
            required
        />

        <input type="submit" class="hidden" :disabled="isMissingField"> <!-- allows user to submit with 'enter' -->
    </form>

    <template #footer>
        <button 
            class="rounded-md py-4 px-6 bg-primary text-white" 
            :class="disabledCss('bg-neutral-300', 'opacity-75', 'hover:bg-neutral-300', 'cursor-not-allowed', 'shadow-none')" 
            :disabled="isMissingField"
            @click="submitUpdate" 
        >
            Confirm
        </button>
        <button class="rounded-md py-4 px-6" @click="clearUpdateRef">Cancel</button>
    </template>
</Modal>
</template>

<script setup lang="ts">
import type { EarlyDeposit } from '@/api/interfaces/early-deposit-types';
import AutoNumericInput from '@/components/Components/AutoNumericInput.vue';
import Modal from '@/components/Components/Modal/Index.vue';
import { useUpdateEarlyDeposit } from '@/services/early-deposit.service';
import { computed, reactive, watch } from 'vue';

const showUpdateModal = computed<boolean>({
    get: () => !!updateEmdRef.value,
    set: (flag) => { !flag && clearUpdateRef() }
});
const emit = defineEmits<{
    'emd-updated': [updatedEmd: EarlyDeposit],
}>();
const payloadFields: Partial<EarlyDeposit> = reactive({});
const isMissingField = computed<boolean>(() => !payloadFields.id || !payloadFields.address || !payloadFields.deposit_amount)

const { runUpdate, clearUpdateRef, updateEmdRef, data: emdResponseRef, error } = useUpdateEarlyDeposit();

const submitUpdate = async () => {
    await runUpdate(payloadFields);
    if (error.value) return;
    emit('emd-updated', emdResponseRef.value!);
    clearUpdateRef();
}

const disabledCss = (...classes: string[]): string =>
    classes.reduce((acc, css) => `${acc} disabled:${css}`, '');

watch(updateEmdRef, (rowData) => {
    // sets initial values for <input> fields
    Object.assign(payloadFields, rowData);
})
</script>

<style scoped>
.update-emd-modal input {
    line-height: 1;
}

.font-hind-open-sans {
    font-family: Hind, 'Open Sans', Arial, serif;
}
</style>
