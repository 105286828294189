<template>
<Modal
    v-model:visible="showDeleteModal"
    footer-class="justify-end"
    title="Delete EMD"
    fullscreen-on-mobile
    :event-to-close-function="clearDeleteRef"
>    
    <p v-if="error" class="bg-rose-100 text-danger py-4 px-6 rounded-md text-base">
        There was an error deleting this record. Please try again, or contact support if you continue to experience the problem.
    </p>

    <div class="grid grid-cols-2 gap-y-2">
        <span>Property Address</span>
        <span class="font-hind-open-sans">{{ deleteEmdRef!.address }}</span>

        <span>Clients</span>
        <ul class="font-hind-open-sans">
            <li class="list-none text-base" v-for="client in deleteEmdRef!.clients">{{ client.email }}</li>
        </ul>

        <span>Deposit Amount</span>
        <span class="font-hind-open-sans">{{ formatMoney((deleteEmdRef!.deposit_amount ?? 0) / 100) }}</span>
    </div>

    <template #footer>
        <button class="rounded-md py-4 px-6 bg-danger text-white" @click="confirmDelete" :disabled="!deleteEmdRef">Confirm</button>
        <button class="rounded-md py-4 px-6" @click="clearDeleteRef">Cancel</button>
    </template>
</Modal>
</template>

<script setup lang="ts">
import type { EarlyDeposit } from '@/api/interfaces/early-deposit-types';
import Modal from '@/components/Components/Modal/Index.vue';
import { useDeleteEarlyDeposit } from '@/services/early-deposit.service';
import { formatMoney } from '@/shared/utils/format-number';
import { computed } from 'vue';

const showDeleteModal = computed<boolean>({
    get: () => !!deleteEmdRef.value,
    set: (flag) => { !flag && clearDeleteRef() }
});
const emit = defineEmits<{
    'emd-deleted': [emdId: EarlyDeposit['id']],
}>();

const { error, deleteEmdRef, clearDeleteRef, runDelete } = useDeleteEarlyDeposit();

const confirmDelete = async () => {
    await runDelete();
    if (error.value) return;
    emit('emd-deleted', deleteEmdRef.value!.id);
    clearDeleteRef();
}
</script>

<style scoped>
.font-hind-open-sans {
    font-family: Hind, 'Open Sans', Arial, serif;
}
</style>
