<template>
<Paginator
    :rows="paginatorInfo.per_page"
    :total-records="paginatorInfo.total"
    :first="zeroIndexedFrom"
    :last="zeroIndexedTo"
    @page="goToPage"
/>
<p class="text-center from-to-total">{{paginatorInfo.from}} to {{paginatorInfo.to}} of {{paginatorInfo.total}}</p>
</template>

<script setup lang="ts">
import { useEarlyDepositsPaged, useExpandedRows } from '@/services/early-deposit.service';
import Paginator, { PageState } from 'primevue/paginator';
import { computed } from 'vue';

const { paginatorInfo, tablePageNum } = useEarlyDepositsPaged();
const { resetExpandedRows } = useExpandedRows()
const emit = defineEmits<{ pageChange: [page: number] }>();
const zeroIndexedFrom = computed(() => paginatorInfo.value.from - 1);
const zeroIndexedTo = computed(() => paginatorInfo.value.to - 1);

const goToPage = ({ page }: PageState) => {
    resetExpandedRows();
    tablePageNum.value = page + 1;
    emit('pageChange', page + 1);
}
</script>

<style scoped>
.from-to-total {
    word-spacing: 0.2ch;
}
</style>
